@import url("https://fonts.googleapis.com/css2?family=Cookie&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700;800;900&display=swap");
.b-0 {
  border: none;
}
.bg-rate {
  background: url(../imges/back2.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-origin: inherit;
  background-position: center;
}
.bg-individual {
  background: url(../imges/sponsor-image.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-origin: content-box;
  background-position: top;
  border-radius: 22px;
  height: 380px;
}
.border8 {
  border-radius: 8px;
}
.bg-yellowgra {
  background: linear-gradient(270deg, #e09400 0%, #fdc24f 100%);
  border-radius: 5px;
}
.testimonial {
  margin: 0 15px;
}
.pic {
  display: block;
  width: 50px;
  height: 50px;
  margin: 0 auto 20px;
}
.pic img {
  width: 100%;
  height: auto;
  border-radius: 50%;
}
.testimonial-title {
  display: block !important;
  text-align: center !important;
  font-size: 12px !important;
  font-weight: 700 !important;
  color: #fff !important;
  margin-bottom: 30px !important;
  text-transform: capitalize !important;
}
.testimonial-review {
  background: linear-gradient(275.07deg, #f2bf35 -0.15%, #d69228 100%);
  border-radius: 10px;
  padding: 25px;
  position: relative;
  height: 202px;
}
.testimonial-review:before {
  content: "";
  border-bottom: 20px solid #e3a72e;
  border-left: 20px solid transparent;
  border-right: 20px solid transparent;
  position: absolute;
  top: -15px;
  left: 46%;
}
.testimonial-review .description {
  color: #fff;
  margin: 0;
  font-size: 14px;
  line-height: 25px;
}
@media screen and (max-width: 990px) {
  .testimonial {
    margin: 0;
  }
}

/*PRESS SLIDER**/
.press-slide {
  /* background: #fff; */
  margin: 0 15px;
}
.press-slide .post-img {
  position: relative;
  float: left;
  width: 50%;
  height: auto;
}
.press-slide .post-img:hover .over-layer {
  opacity: 1;
}
.press-slide .post-img img {
  width: 33%;
  height: auto;
}
.press-slide .post-review {
  float: left;
  padding: 1px 20px;
  width: 50%;
}
.press-slide .post-title {
  margin: 0 0 5px 0;
}
.press-slide .post-title a {
  color: #db9a2a;
  font-size: 16px;
  font-weight: 700;
  display: block;
  text-transform: capitalize;
  transition: color 0.3s ease;
}
.post-title > a:hover {
  text-decoration: none;
  color: #333;
}
.press-slide .post-date {
  display: block;
  font-size: 15px;
  font-weight: 700;
  margin-bottom: 17px;
}
.post-description {
  color: #333;
  font-size: 15px;
  font-weight: 400;
  text-align: left;
}

@media only screen and (max-width: 990px) {
  .press-slide .over-layer:after {
    top: 39%;
    left: 45%;
  }
}
@media only screen and (max-width: 640px) {
  .press-slide .post-img {
    width: 100%;
  }
  .press-slide .post-review {
    width: 100%;
    padding: 10px;
  }
}
.txt-gold {
  color: #e9bf53 !important;
}
@import url("https://fonts.googleapis.com/css?family=Roboto");

.back-to-top {
  position: fixed;
  display: none;
  right: 30px;
  bottom: 30px;
  z-index: 99;
}
.flot-signin {
  position: fixed;
  display: none;
  right: 70px;
  bottom: 60px;
  z-index: 99;
}

.topbar-right {
  position: relative;
  background: linear-gradient(275.07deg, #f2bf35 -0.15%, #d69228 100%);
}

.topbar-right::before {
  position: absolute;
  content: "";
  width: 30px;
  height: 100%;
  top: 0;
  left: -15px;
  transform: skewX(-30deg);
  background: linear-gradient(275.07deg, #f2bf35 -0.15%, #d69228 100%);
}
/** blog slidder **/
.cards-wrapper {
  padding: 50px 15px;
}
.info {
  font-size: 12px;
  display: flex;
  padding: 1em 3em;
  height: 3em;
}
.info img {
  height: 3em;
  margin-right: 0.5em;
}
.info h1 {
  font-size: 1em;
  font-weight: normal;
}

/* MEDIA QUERIES */
@media screen and (max-width: 1285px) {
  .cards-wrapper {
    grid-template-columns: 1fr 1fr;
  }
}
@media screen and (max-width: 990px) {
  .cards-wrapper {
    padding: 50px 15px;
  }
}
@media screen and (max-width: 900px) {
  .cards-wrapper {
    grid-template-columns: 1fr;
    padding: 0;
  }
  .info {
    justify-content: center;
  }
  .card-grid-space .num {
    margin-left: 0;
    text-align: center;
  }
}

@media screen and (max-width: 500px) {
  .cards-wrapper {
    padding: 0;
  }
  .card {
    max-width: calc(100vw - 4rem);
  }
}

@media screen and (max-width: 450px) {
  .info {
    display: block;
    text-align: center;
  }
  .info h1 {
    margin: 0;
  }
}

.btn-icon {
  color: #fff !important;
  background: linear-gradient(
    275.07deg,
    #f2bf35 -0.15%,
    #d69228 100%
  ) !important;
  border-color: #efba33 !important;
}
.btn-icon:hover {
  color: #000 !important;
}

/** How DoAram works**/
.main-timeline {
}
.main-timeline:after {
  content: "";
  display: block;
  clear: both;
}
.main-timeline .timeline {
  margin: 0 -15px;
}
.main-timeline .timeline-content {
  color: #d79329;
  text-align: left;
  display: block;
  position: relative;
}
.main-timeline .timeline-content:hover {
  text-decoration: none;
}
.main-timeline .timeline-icon {
  font-size: 70px;
  text-align: center;
  margin-bottom: 10px;
  transition: all 0.3s;
}
.main-timeline .timeline:hover .timeline-icon {
  transform: rotateY(360deg);
}
.main-timeline .timeline-year {
  margin-bottom: 20px;
  position: relative;
  z-index: 1;
}
.main-timeline .yr1:after {
  content: "";
  height: 60px;
  width: 107%;
  background: linear-gradient(275.07deg, #f2bf35 -0.15%, #d69228 100%);
  transform: translateX(-50%) translateY(-50%);
  position: absolute;
  left: 50%;
  top: 50%;
  z-index: -1;
  -webkit-clip-path: polygon(
    90% 0,
    100% 50%,
    90% 100%,
    0% 100%,
    10% 50%,
    0% 0%
  );
  clip-path: polygon(90% 0, 100% 50%, 90% 100%, 0% 100%, 10% 50%, 0% 0%);
}
.main-timeline .yr2:after {
  content: "";
  height: 60px;
  width: 87%;
  background-color: #a76401;
  /* background-color: #ea2027; */
  transform: translateX(-50%) translateY(-50%);
  position: absolute;
  left: 50%;
  top: 50%;
  z-index: -1;
  /* -webkit-clip-path: polygon(90% 0, 100% 50%, 90% 100%, 0% 100%, 10% 50%, 0% 0%); */
  clip-path: polygon(100% 0, 100% 100%, 100% 100%, 0% 100%, 0% 50%, 0% 0%);
}
.main-timeline .yr3:after {
  content: "";
  height: 60px;
  width: 107%;
  background: linear-gradient(275.07deg, #f2bf35 -0.15%, #d69228 100%);
  transform: translateX(-50%) translateY(-50%);
  position: absolute;
  left: 50%;
  top: 50%;
  z-index: -1;
  -webkit-clip-path: polygon(
    90% 0,
    100% 50%,
    90% 100%,
    0% 100%,
    10% 50%,
    0% 0%
  );
  clip-path: polygon(100% 0, 89% 50%, 100% 100%, 10% 100%, 0% 50%, 10% 0%);
}
.main-timeline .timeline-year span {
  color: #fff;
  background-color: #d79329;
  font-size: 35px;
  font-weight: 600;
  text-align: center;
  line-height: 120px;
  height: 120px;
  width: 120px;
  margin: 0 auto;
  border-radius: 50%;
  box-shadow: 0 0 10px #999, 0 0 0 15px #fff;
  display: block;
}
.main-timeline .inner-content {
  padding: 0 10px 10px 15px;

  text-align: center;
}
.main-timeline .title {
  font-size: 25px;
  font-weight: 600;
  margin: 0 0 15px 0;
}
.main-timeline .description {
  color: #fff;
  font-size: 15px;
  letter-spacing: 1px;
  text-align: justify;
}
.main-timeline .timeline.blue .timeline-icon,
.main-timeline .timeline.blue .title {
  color: #a76401;
}
.main-timeline .timeline.blue .timeline-year:after,
.main-timeline .timeline.blue .timeline-year span {
  background-color: #a76401;
}
.main-timeline .timeline.blue .inner-content {
  border-left-color: #a76401;
}
.main-timeline .timeline.green .timeline-icon,
.main-timeline .timeline.green .title {
  color: #f2bf35;
}
.main-timeline .timeline.green .timeline-year:after,
.main-timeline .timeline.green .timeline-year span {
  background-color: #f2bf35;
}
.main-timeline .timeline.green .inner-content {
  border-left-color: #f2bf35;
}
.main-timeline .timeline.pink .timeline-icon,
.main-timeline .timeline.pink .title {
  color: #e02a6d;
}
.main-timeline .timeline.pink .timeline-year:after,
.main-timeline .timeline.pink .timeline-year span {
  background-color: #e02a6d;
}
.main-timeline .timeline.pink .inner-content {
  border-left-color: #e02a6d;
}
@media screen and (max-width: 767px) {
  .main-timeline .timeline {
    margin: 0 0 15px;
  }
  .main-timeline .timeline-year:after {
    width: 100%;
  }
}

/***corperate service*/
.serviceBox1 {
  text-align: center;
  padding: 0 23px 20px;
  position: relative;
  z-index: 1;
  margin-top: -75px;
  height: 395px;
}
.serviceBox1:before {
  content: "";
  background: #2d1621;
  width: 100%;
  height: calc(100% - 80px);
  border: 15px solid #e2ba2d;
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: -1;
}
.serviceBox1 .service-icon {
  color: #38241d;
  background: linear-gradient(135deg, #efba33, #efba33, #efba33);
  font-size: 60px;
  line-height: 130px;
  width: 130px;
  height: 130px;
  margin: 0 auto 25px;
  border-radius: 50%;
  box-shadow: 2px 2px 2px rgb(255 187 15);
}
.serviceBox1 .title {
  color: #efba33 !important;
  font-size: 15px;
  font-weight: 600;
  text-transform: capitalize;
  margin: 0 0 7px;
}
.serviceBox1 .description {
  color: #efba33;
  font-size: 14px;
  line-height: 20px;
}
.serviceBox1.yellow:before {
  border-color: #faa725;
}
.serviceBox1.yellow .service-icon {
  color: #faa725;
}
.serviceBox1.red:before {
  border-color: #e6491e;
}
.serviceBox1.red .service-icon {
  color: #e6491e;
}
.serviceBox1.green:before {
  border-color: #18bb9c;
}
.serviceBox1.green .service-icon {
  color: #18bb9c;
}
@media only screen and (max-width: 990px) {
  .serviceBox1 {
    margin: 0 0 30px;
  }
}
.post-slide2 {
  text-align: center;
}
.post-slide2 .post-img {
  overflow: hidden;
  display: flex;
  justify-content: center;
}
.post-slide2 .post-img img {
  width: 70%;
  height: 150px;
  transform: scale(1);
  transition: all 1s ease-in-out 0s;
  text-align: center;
}
.post-slide2:hover .post-img img {
  transform: scale(1.08);
}
.post-slide2 .post-content {
  /* background: #fff; */
  padding: 26px;
}
.post-slide2 .post-title {
  font-size: 17px !important;
  font-weight: 600 !important;
  margin-top: 0;
  text-transform: capitalize;
  text-align: center;
}
.post-slide2 .post-title a {
  display: inline-block;
  color: #040404;
  transition: all 0.3s ease 0s;
}
.post-slide2 .post-title a:hover {
  color: #3d3030;
  text-decoration: none;
}
.post-slide2 .post-description {
  font-size: 15px;
  color: #676767;
  line-height: 24px;
  margin-bottom: 14px;
  text-align: center;
}
.post-slide2 .post-bar {
  padding: 0;
  margin-bottom: 15px;
  list-style: none;
}
.post-slide2 .post-bar li {
  color: #676767;
  padding: 2px 0;
}
.post-slide2 .post-bar li i {
  margin-right: 5px;
}
.post-slide2 .post-bar li a {
  display: inline-block;
  font-size: 12px;
  color: #808080;
  transition: all 0.3s ease 0s;
}
.post-slide2 .post-bar li a:after {
  content: ",";
}
.post-slide2 .post-bar li a:last-child:after {
  content: "";
}
.post-slide2 .post-bar li a:hover {
  color: #3d3030;
  text-decoration: none;
}
.post-slide2 .read-more {
  display: inline-block;
  padding: 10px 15px;
  font-size: 14px;
  font-weight: bold;
  color: #fff;
  background: #995f04;
  border-bottom-right-radius: 10px;
  text-transform: capitalize;
  transition: all 0.3s linear;
  display: flex;
  justify-content: center;
}
.post-slide2 .read-more:hover {
  background: #d79429;
  text-decoration: none;
}
/*** Header ***/
@media (max-width: 768px) {
  #header-carousel .carousel-item {
    position: relative;
    min-height: 450px;
  }

  #header-carousel .carousel-item img {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.page-header {
  background: linear-gradient(
      to right,
      rgba(2, 36, 91, 1) 0%,
      rgba(2, 36, 91, 0) 100%
    ),
    url(../imges/carousel-2.jpg) center center no-repeat;
  background-size: cover;
}

.page-header .breadcrumb-item + .breadcrumb-item::before {
  color: var(--light);
}

.page-header .breadcrumb-item,
.page-header .breadcrumb-item a {
  font-size: 18px;
  color: var(--light);
}

/*** Features ***/
@keyframes pulse-border {
  0% {
    transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1);
    opacity: 1;
  }

  100% {
    transform: translateX(-50%) translateY(-50%) translateZ(0) scale(2);
    opacity: 0;
  }
}

/*** Team ***/
.team-item .team-social {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: -100%;
  display: flex;
  align-items: center;
  background: #edb733;
  transition: 0.5s;
  color: #2e0101;
  font-weight: 600;
  padding: 20px;
}

.team-item:hover .team-social {
  left: 0;
}

.font10 {
  font-size: 10px !important;
}
.font11 {
  font-size: 11px !important;
}
.font12 {
  font-size: 12px !important;
}
.font13 {
  font-size: 13px !important;
}
.font14 {
  font-size: 14px !important;
}
.font15 {
  font-size: 15px !important;
}
.font16 {
  font-size: 16px !important;
}
.font17 {
  font-size: 17px !important;
}
.font18 {
  font-size: 18px !important;
}
.font20 {
  font-size: 20px !important;
}

/***********/
.cardStyle {
  /* --bg-color: #ffd861; */
  --bg-color-light: #ffeeba;
  --text-color-hover: #4c5656;
  --box-shadow-color: rgb(157 156 156);
}

.cardStyle:hover {
  transform: translateY(-5px) scale(1.005) translateZ(0);
  background: #ffffff;
  border: 1px solid #dc9c2b;
  box-shadow: 0px 27px 20px -9px rgba(0, 0, 0, 0.16);
  border-radius: 5px;
}

.card:hover .overlay {
  transform: scale(4) translateZ(0);
}

.card:hover .circle {
  border-color: var(--bg-color-light);
  background: var(--bg-color);
}

.card:hover .circle:after {
  background: var(--bg-color-light);
}

.card:hover p {
  color: var(--text-color-hover);
}

.card:active {
  transform: scale(1) translateZ(0);
  box-shadow: 0 15px 24px rgba(0, 0, 0, 0.11),
    0 15px 24px var(--box-shadow-color);
}

.card p {
  font-size: 13px;
  color: #2d1621;
  margin-top: 16px;
  z-index: 1000;
  transition: color 0.3s ease-out;
}

.circles img {
  width: 90px;
  height: 90px;
}

::-moz-range-track {
  background: transparent;
  border: 0;
}

.c-details span {
  font-weight: 300;
  font-size: 13px;
}

.icon {
  width: 50px;
  height: 50px;
  background-color: #eee;
  border-radius: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 39px;
}

.badge span {
  background-color: #fffbec;
  width: 60px;
  height: 25px;
  padding-bottom: 3px;
  border-radius: 5px;
  display: flex;
  color: #fed85d;
  justify-content: center;
  align-items: center;
}

.progress {
  height: 10px;
  border-radius: 10px;
}

.text1 {
  font-size: 14px;
  font-weight: 600;
  color: #000;
}

.text2 {
  color: #a5aec0;
}
.post-slide .post-link li a:hover {
  color: #ff8b3d;
  text-decoration: none;
}
.post-slide .post-date {
  position: absolute;
  top: 10%;
  left: 4%;
}
.post-slide .date {
  display: inline-block;
  border-radius: 3px 0 0 3px;
  padding: 5px 10px;
  color: #fff;
  font-size: 20px;
  font-weight: bold;
  text-align: center;
  background: #333;
  float: left;
}
.post-slide .month {
  display: inline-block;
  border-radius: 0 3px 3px 0;
  padding: 5px 13px;
  color: #111;
  font-size: 20px;
  font-weight: bold;
  background: #ff8b3d;
}
.post-slide .post-content {
  padding: 30px;
}
.post-slide .post-title {
  margin: 0 0 15px 0;
}
.post-slide .post-title a {
  font-size: 18px;
  font-weight: bold;
  color: #333;
  display: inline-block;
  text-transform: capitalize;
  transition: all 0.3s ease 0s;
}
.post-slide .post-title a:hover {
  text-decoration: none;
  color: #d0992a;
}
.post-slide .post-description {
  font-size: 14px;
  line-height: 24px;
  color: #808080;
}
.post-slide .read-more {
  color: #333;
  font-size: 14px;
  font-weight: bold;
  text-transform: uppercase;
  position: relative;
  transition: color 0.2s linear;
}
.post-slide .read-more:hover {
  text-decoration: none;
  color: #ff8b3d;
}
.post-slide .read-more:after {
  content: "";
  position: absolute;
  width: 30%;
  display: block;
  border: 1px solid #ff8b3d;
  transition: all 0.3s ease;
}
.post-slide .read-more:hover:after {
  width: 100%;
}
@media only screen and (max-width: 479px) {
  .post-slide .month {
    font-size: 14px;
  }
  .post-slide .date {
    font-size: 14px;
  }
}
/*** Navbar ***/
.navbar.sticky-top {
  top: -100px;
  transition: 0.5s;
}

.navbar .navbar-brand {
  position: relative;
  padding-right: 50px;
  height: 75px;
  display: flex;
  align-items: center;
}

.navbar .navbar-brand::after {
  position: absolute;
  content: "";
  width: 50px;
  height: 100%;
  top: 0;
  right: -25px;
  transform: skewX(-30deg);
}

.navbar .dropdown-toggle::after {
  border: none;
  content: "\f107";
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  vertical-align: middle;
  margin-left: 8px;
}

@media (max-width: 991.98px) {
  .navbar .navbar-nav .nav-link {
    margin-right: 0 !important;
    padding: 10px 0 !important;
  }

  .navbar .navbar-nav {
    border-top: 1px solid #eeeeee;
  }
}

@media (min-width: 992px) {
  .navbar .nav-item .dropdown-menu {
    display: block;
    border: none;
    margin-top: 0;
    top: 150%;
    opacity: 0;
    visibility: hidden;
    transition: 0.5s;
  }

  .navbar .nav-item:hover .dropdown-menu {
    top: 100%;
    visibility: visible;
    transition: 0.5s;
    opacity: 1;
  }
}

/* ...........Bootstrap edited......................... */
.text-white {
  color: #fff !important;
}
.bgc-secondary {
  background-color: #2d1621 !important;
}
.txt-warning {
  color: #c7981b !important;
}
.ft-10 {
  font-size: 10px !important;
}
.ft-12 {
  font-size: 12px !important;
}
.ft-14 {
  font-size: 14px !important;
}
.btn-sm {
  font-size: 12px !important;
}
.bgc-white {
  background-color:white  !important;
}

.bgc-dark {
  background-color:#2d1621  !important;
}
.btn-secondary {
  color: #fff !important;
  background-color: #2d1621 !important;
  border-color: #795548 !important;
}
.footer .btn.btn-link {
  text-decoration: none !important;
  font-size: 12px;
}
a {
  text-decoration: none !important;
}
.btn-warning {
  color: #fff !important;
  background: linear-gradient(
    275.07deg,
    #f2bf35 -0.15%,
    #d69228 100%
  ) !important;
  border-color: #f2bf35 !important;
}
.btn-green {
  color: #fff !important;
  background: linear-gradient(
    275.07deg,
    #27cb1b -0.15%,
    #27cb1b 100%
  ) !important;
  border-color: #27cb1b !important;

}

.btn-violet {
  color: #fff !important;
  background: linear-gradient(90.15deg, #694155 0.12%, #bb7397 99.87%);
  border-color: none !important;
  border-radius: 5px;
}

.display-5 {
  font-size: 3rem !important;
  font-weight: 700 !important;
  line-height: 1.2 !important;
}

.txt-primary {
  color: #7c022b !important;
}
.bg-warning {
  background: linear-gradient(
    275.07deg,
    #f2bf35 -0.15%,
    #d69228 100%
  ) !important;
}
.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0px;
}
.displaytxt-5 {
  font-size: 3rem !important;
  font-weight: 600 !important;
}

/* ...........................problem css........................... */
h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6 {
  margin-top: 0;
  margin-bottom: 0.5rem !important;
  font-family: "Poppins", sans-serif;
  font-weight: 700 !important;
  line-height: 1.2 !important;
  color: #2d1621 !important;
  font-size: 25px !important;
}

.webbody {
  margin: 0;
  font-family: "Poppins" !important;
  font-size: 12px !important;
  font-weight: 400 !important;
  line-height: 1.5 !important;
  color: #000000 !important;
  /* background-color: #817d7d !important; */
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  padding-top: 0px;
  /* background: #eeeeee; */
}
h1,
h2,
.h1,
.h2,
.fw-bold {
  font-weight: 700 !important;
}

h3,
h4,
.h3,
.h4,
.fw-medium {
  font-weight: 600 !important;
}

h5,
h6,
.h5,
.h6,
.fw-semi-bold {
  font-weight: 500 !important;
}

/*** Button ***/
.btn {
  transition: 0.5s;
  font-weight: 500;
}

.btn-primary,
.btn-outline-primary:hover {
  color: #ffffff;
}

.btn-square {
  width: 30px;
  height: 30px;
}

.btn-sm-square {
  width: 32px;
  height: 32px;
}

.btn-lg-square {
  width: 48px;
  height: 48px;
}

.btn-square,
.btn-sm-square,
.btn-lg-square {
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: normal;
}
.card {
  --bg-filter-opacity: 0.5;
  background-image: linear-gradient(
      rgba(0, 0, 0, var(--bg-filter-opacity)),
      rgba(0, 0, 0, var(--bg-filter-opacity))
    ),
    var(--bg-img);
  height: 20em;
  font-size: 1.5em;
  color: white;
  border-radius: 1em;
  padding: 1em;

  transition: all, var(--transition-time);
  position: relative;
  overflow: hidden;
  /* border: 10px solid #ccc; */
  text-decoration: none;
}

.cardStyle:hover {
  transform: rotate(0);
}

.card h1 {
  margin: 0;
  font-size: 1.5em;
  line-height: 1.2em;
}

.card .date {
  position: absolute;
  top: 0;
  right: 0;
  font-size: 0.75em;
  padding: 1em;
  line-height: 1em;
  opacity: 0.8;
}

.cardStyle:before,
.cardStyle:after {
  content: "";
  transform: scale(0);
  transform-origin: top left;
  border-radius: 50%;
  position: absolute;
  left: -50%;
  top: -50%;
  z-index: -5;
  transition: all, var(--transition-time);
  transition-timing-function: ease-in-out;
}

.card:before {
  background: #ddd;
  /* width: 250%; */
  height: 250%;
}

.card:after {
  background: white;
  width: 200%;
  height: 200%;
}

/* corporate web affect */
/* .card:hover {
  color: var(--color);
} */

.cardStyle:hover:before,
.cardStyle:hover:after {
  transform: scale(1);
}

.card-grid-space .num {
  font-size: 3em;
  margin-bottom: 1.2rem;
  margin-left: 1rem;
}
.post-slide {
  border-radius: 14px;
  margin: 0 15px;
  position: relative;
  background: #fff;
}
.post-slide .post-img {
  position: relative;
  overflow: hidden;
}
.post-slide .post-img img {
  width: 100%;
  height: auto;
}
.post-slide .over-layer {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  background: rgba(0, 0, 0, 0.6);
  transition: all 0.3s ease;
}
.post-slide:hover .over-layer {
  opacity: 1;
}
.post-slide .post-link {
  margin: 0;
  padding: 0;
  position: relative;
  top: 45%;
  text-align: center;
}
.post-slide .post-link li {
  display: inline-block;
  list-style: none;
  margin-right: 20px;
}
.post-slide .post-link li a {
  color: #fff;
  font-size: 20px;
}
.post-slide .post-link li a:hover {
  color: #ff8b3d;
  text-decoration: none;
}
.post-slide .post-date {
  position: absolute;
  top: 10%;
  left: 4%;
}
.post-slide .date {
  display: inline-block;
  border-radius: 3px 0 0 3px;
  padding: 5px 10px;
  color: #fff;
  font-size: 20px;
  font-weight: bold;
  text-align: center;
  background: #333;
  float: left;
}
.post-slide .month {
  display: inline-block;
  border-radius: 0 3px 3px 0;
  padding: 5px 13px;
  color: #111;
  font-size: 20px;
  font-weight: bold;
  background: #ff8b3d;
}
.post-slide .post-content {
  padding: 30px;
}
.post-slide .post-title {
  margin: 0 0 15px 0;
}
.post-slide .post-title a {
  font-size: 18px;
  font-weight: bold;
  color: #333;
  display: inline-block;
  text-transform: capitalize;
  transition: all 0.3s ease 0s;
}
.post-slide .post-title a:hover {
  text-decoration: none;
  color: #d0992a;
}
.post-slide .post-description {
  font-size: 14px;
  line-height: 24px;
  color: #808080;
}
.post-slide .read-more {
  color: #333;
  font-size: 14px;
  font-weight: bold;
  text-transform: uppercase;
  position: relative;
  transition: color 0.2s linear;
}
.post-slide .read-more:hover {
  text-decoration: none;
  color: #ff8b3d;
}
.post-slide .read-more:after {
  content: "";
  position: absolute;
  width: 30%;
  display: block;
  border: 1px solid #ff8b3d;
  transition: all 0.3s ease;
}
.post-slide .read-more:hover:after {
  width: 100%;
}
@media only screen and (max-width: 479px) {
  .post-slide .month {
    font-size: 14px;
  }
  .post-slide .date {
    font-size: 14px;
  }
}

/*** Footer ***/
.footer {
  color: #b0b9ae;
}

.footer .btn.btn-link {
  display: block;
  margin-bottom: 5px;
  padding: 0;
  text-align: left;
  color: #b0b9ae;
  font-weight: normal;
  text-transform: capitalize;
  transition: 0.3s;
}

.footer .btn.btn-link::before {
  position: relative;
  content: "\f105";
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  margin-right: 10px;
}

.footer .btn.btn-link:hover {
  color: var(--light);
  letter-spacing: 1px;
  box-shadow: none;
}

.fa-hand-rock-o:before {
  content: "\f255";
}
.fa-inr:before {
  content: "\f156";
}
.fa-external-link:before {
  content: "\f08e";
  color: #000;
}

.fa-money:before {
  content: "\f0d6";
  color: #000;
}
.copyright {
  color: #b0b9ae;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
}

.cardfilter {
  background: #fff;
  border-top-right-radius: 10px;
  overflow: hidden;
  flex-direction: column;
  justify-content: left;
  align-items: start;
  position: relative;
  box-shadow: 0 14px 26px rgb(211 211 211);
  transition: all 0.3s ease-out;
  text-decoration: none;
  border-radius: 5px;
}
.overlay {
  width: 118px;
  position: absolute;
  height: 118px;
  border-radius: 50%;
  background: var(--bg-color);
  top: 70px;
  left: 50px;
  z-index: 0;
  transition: transform 0.3s ease-out;
  display: none;
}

.txthome {
  max-width: 600px;
}
.aboutimg {
  height: 200px !important;
}
.teamimg {
  min-width: 90px !important;
  min-height: 100px !important;
}
.containerbox {
  height: 100px !important;
}
.factimg {
  height: 200px !important;
}
.img {
  height: 260px;
  width: 100%;
}
.work {
  width: 100%;
}
.work .img {
  width: 100%;
  height: 500px;
  position: relative;
  overflow: hidden;
  -webkit-box-shadow: 0px 20px 35px -30px rgba(0, 0, 0, 0.26);
  -moz-box-shadow: 0px 20px 35px -30px rgba(0, 0, 0, 0.26);
  box-shadow: 0px 20px 35px -30px rgba(0, 0, 0, 0.26);
  z-index: 0;
}
.work .img:after {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  content: "";
  z-index: -1;
  background: rgba(255, 93, 177, 0);
  background: -moz-linear-gradient(
    top,
    rgba(255, 93, 177, 0) 0%,
    rgba(148, 54, 103, 0) 42%,
    black 100%
  );
}
.header nav a.active {
  color: #1f2d3d;
  text-decoration: none;
}
.header .navbar {
  color: #ff5e14;
}
.header .bg-gold {
  background: linear-gradient(275.07deg, #f2bf35 -0.15%, #d69228 100%);
}
.yellow .owl-theme .owl-dots .owl-dot.active span {
  background-color: #d89529;
}
.blogcategory {
  height: 25vh !important;
  overflow-y: scroll;
}
.btnnca {
  font-family: "Montserrat" !important;
  border: none;
}
.bg_dtheme {
  background: #2d1621;
  /* color: #ffffff; */
}
.form-floating > label {
  color: #2d1621;
  font-size: 16px;
  font-family: "Poppins";
  font-weight: normal;

}

.btn-squarenca {
  position: absolute;
  transform: translateX(-50%);
}

.btn-iconnca {
  color: #fff;
  background: linear-gradient(275.07deg, #f2bf35 -0.15%, #d69228 100%);
  border-color: #efba33;
}

.btn-iconnca:hover {
  color: #000;
}

.d-input-group-text {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: center;
  white-space: nowrap;
  background-color: #ffffff;
  border: 1px solid #ced4da;
  border-radius: 0.375rem;
  height: 61px;
}
.box-org {
  position: absolute;
  width: 34px;
  height: 17px;

  background: #f8c73d;
  box-shadow: 0px 7px 15px rgba(0, 0, 0, 0.19);
  border-radius: 39px;
}

.box-pink {
  position: absolute;
  width: 34px;
  height: 17px;

  background: linear-gradient(90.15deg, #694155 0.12%, #bb7397 99.87%);
  border-radius: 8px;
}
.themeCol-pink {
  color: #7c0b41;
}

.themeCol-orange {
  color: #d49732;
}

.logbtn {
  --bs-btn-color: #fff;
  --bs-btn-bg: #f8f9fa00;
  /* border: 3px solid #f8f9fa; */
  border-radius: 5px;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #f4c03a;
  --bs-btn-hover-border-color: #f3c03a;
  --bs-btn-focus-shadow-rgb: 211, 212, 213;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #c6c7c8;
  --bs-btn-active-border-color: #babbbc;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #f8f9fa;
  --bs-btn-disabled-border-color: #f8f9fa;
}

.btn-outline-theyellow {
  --bs-btn-border-color: #e6ab30;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #e6ab30;
  --bs-btn-hover-border-color: #e6ab30;
  --bs-btn-focus-shadow-rgb: 255, 193, 7;
  --bs-btn-active-color: #ffffff;
  --bs-btn-active-bg: #e6ab30;
  --bs-btn-active-border-color: #e6ab30;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #e6ab30;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #e6ab30;
  --bs-gradient: none;
  font-size: 15px;
}

.btn-gold {
  color: #fff !important;
  --bs-btn-bg: linear-gradient(275.07deg, #f2bf35 -0.15%, #d69228 100%);
  background: var(--bs-btn-bg);
  --bs-btn-border-color: #d69228;
  --bs-btn-shadow: 0px 6px 6px rgba(0, 0, 0, 0.04);
  --bs-btn-border-radius: 4px;
  border-radius: var(--bs-btn-border-radius);
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #ececed;
  --bs-btn-hover-border-color: #eef2f5;
  --bs-btn-focus-shadow-rgb: 66, 70, 73;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #4d5154;
  --bs-btn-active-border-color: #ffffff;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #212529;
  --bs-btn-disabled-border-color: #212529;
}
.breadcrumbnca {
  font-size: 14px;
}
.btn-red {
  color: #fff !important;
  background: linear-gradient(
    to right top,
    #e11d1d,
    #e41a24,
    #e6172b,
    #e91432,
    #eb1238
  );
  border-color: none !important;
}
.btn-blue {
  color: #fff !important;
  background: linear-gradient(
    to right top,
    #2d7673,
    #007a8e,
    #007aae,
    #0075c9,
    #5067d3
  );
  border-color: none !important;
}
.btn-pink {
  color: #fff !important;
  --bs-btn-bg: linear-gradient(90.15deg, #694155 0.12%, #bb7397 99.87%);
  background: var(--bs-btn-bg);
  --bs-btn-border-color: #694155;
  --bs-btn-shadow: 0px 6px 6px rgba(0, 0, 0, 0.04);
  --bs-btn-border-radius: 4px;
  border-radius: var(--bs-btn-border-radius);
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #ececed;
  --bs-btn-hover-border-color: #eef2f5;
  --bs-btn-focus-shadow-rgb: 66, 70, 73;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #4d5154;
  --bs-btn-active-border-color: #ffffff;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #212529;
  --bs-btn-disabled-border-color: #212529;
}
.btn-green {
  --bs-btn-color: #fff;
  --bs-btn-bg: linear-gradient(275.07deg, #4bf235 -0.15%, #37bb1c 100%);
  background: var(--bs-btn-bg);
  --bs-btn-border-color: #37bb1c;
  --bs-btn-shadow: 0px 6px 6px rgba(0, 0, 0, 0.04);
  --bs-btn-border-radius: 4px;
  border-radius: var(--bs-btn-border-radius);
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #ececed;
  --bs-btn-hover-border-color: #eef2f5;
  --bs-btn-focus-shadow-rgb: 66, 70, 73;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #4d5154;
  --bs-btn-active-border-color: #ffffff;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #212529;
  --bs-btn-disabled-border-color: #212529;
}

/* ---------------------------------DOARAM -------------------------------------------------------------- */
.btn-orange {
  color: #fff !important;
  background:#e97719d0 !important;
  border: none;

 
}
.pricetxt {
  font-size: 10px;
  padding-left: 300px;
}
/* If the screen size is 601px wide or more, set the font-size of <div> to 80px */
@media screen and (min-width: 601px) {
  .pricetxt {
    font-size: 10px;
    padding-left: 270px;
  }
}

/* If the screen size is 600px wide or less, set the font-size of <div> to 30px */
@media screen and (max-width: 600px) {
  .pricetxt {
    font-size: 10px;
    padding-left: 150px;
  }
}

.input-tgroup {
  background-color: #fff !important;
  border: 1px solid #ced4da;
  padding-bottom: 4px;
}

.custom-file-uploader {
  position: relative;
}
.custom-file-uploader input[type="file"] {
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 5;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: default;
}

.fontfamilynca {
  font-family: "Poppins" !important;
  font-size: 12px !important;
}

.footer-social ul,
.footer-nav ul {
  display: flex;
  flex-wrap: wrap;
  justify-content: left !important;
  align-items: center;
  white-space: nowrap;
  margin-bottom: 0;
}
.btn-success {
  background: linear-gradient(270deg, #63e000 0%, #55c14b 100%);
  border: 1px solid #63e000;
}
.accordion-header {
  margin-top: 0px !important;
}
.accordion-header button {
  margin-top: 0px !important;
  font-family: "Poppins" !important;
  font-size: 12px !important;
  /* font-size: 18px !important; */
  /* font-weight: bold !important; */
}
/* homepage web used */
.accordion-body {
  font-family: "Poppins" !important;
  font-size: 12px !important;
}

.accordion-button:not(.collapsed)::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23e65512'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  transform: rotate(-180deg);
  /* background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAZCAYAAAArK+5dAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAADiSURBVHgB7ZTNDYIwFIBfNZyApHdCohvoBh496gQyAo7gBIYJZAN1AkeQETxA6LEHOBJ8TfRCSFtbufW79b3mff17BXA4bJmrJsRxvAvDcOv7PmuahsOPEFkyiqKEEHL5jvu+z7uuOzHGXqDJTJbE4ofBOPE87yHE8A8BrngxFhO7QskZbAVYiEpyKUqeFAFTAUIVC1gFQXAFC4EOG5hSIF4WWAi4onjRtu0RTAVYQCbIqqpacwRMBXiJxUiYY7Pty7JMQQPVDu6DUIbfxbKu6xtoQlQTPl1L8axz1XE4HNPwBs9JUNEMu/kGAAAAAElFTkSuQmCC); */
  /* transform: rotate(-455deg); */
}
/* .accordion-button:not(.collapsed)::after {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAZCAYAAAArK+5dAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAADiSURBVHgB7ZTNDYIwFIBfNZyApHdCohvoBh496gQyAo7gBIYJZAN1AkeQETxA6LEHOBJ8TfRCSFtbufW79b3mff17BXA4bJmrJsRxvAvDcOv7PmuahsOPEFkyiqKEEHL5jvu+z7uuOzHGXqDJTJbE4ofBOPE87yHE8A8BrngxFhO7QskZbAVYiEpyKUqeFAFTAUIVC1gFQXAFC4EOG5hSIF4WWAi4onjRtu0RTAVYQCbIqqpacwRMBXiJxUiYY7Pty7JMQQPVDu6DUIbfxbKu6xtoQlQTPl1L8axz1XE4HNPwBs9JUNEMu/kGAAAAAElFTkSuQmCC);
  transform: rotate(-455deg);
} */
.accordion-button:not(.collapsed) {
  color: #af3700;
  background-color: #fff8e4;
  box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.125);
  /* color: #2ecc71; */
  /* background-color: #f5f5f5; */
  /* box-shadow: inset 0 -1px 0 rgb(0 0 0 / 13%); */
  font-weight: 600;
}
.accordion-button::after {
  flex-shrink: 0;
  width: 1.25rem;
  height: 1.25rem;
  margin-left: auto;
  content: "";
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%235F656F'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  /* background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAZCAYAAAArK+5dAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAADiSURBVHgB7ZTNDYIwFIBfNZyApHdCohvoBh496gQyAo7gBIYJZAN1AkeQETxA6LEHOBJ8TfRCSFtbufW79b3mff17BXA4bJmrJsRxvAvDcOv7PmuahsOPEFkyiqKEEHL5jvu+z7uuOzHGXqDJTJbE4ofBOPE87yHE8A8BrngxFhO7QskZbAVYiEpyKUqeFAFTAUIVC1gFQXAFC4EOG5hSIF4WWAi4onjRtu0RTAVYQCbIqqpacwRMBXiJxUiYY7Pty7JMQQPVDu6DUIbfxbKu6xtoQlQTPl1L8axz1XE4HNPwBs9JUNEMu/kGAAAAAElFTkSuQmCC); */
  background-repeat: no-repeat;
  background-size: 1.25rem;
  transition: transform 0.2s ease-in-out;
  transform: rotate(90deg);
}

.accordion-button:focus {
  border-color: #ffaf8a !important;

  box-shadow: 0 0 0 0.25rem rgba(255, 94, 20, 0.25) !important;
}

/* .h2line:before {
  background-image: url("../doaram/assets/images/yellowline.png");
  background-position: 0 1.06em;
  background-size: 28px 9px;
} */

/* .background-land {
  background-image: url("../doaram/assets/images/bg.png") !important;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 97% 100vh;
  max-width: 100%;
} */
input,
input::placeholder {
  font-size: 14px;
}

input[type="text"],
select,
[type="password"] {
  font-size: 12px;
}

a {
  cursor: pointer;
}
.form {
  background-color: white;
  border-radius: 5px;
  width: 550px;
  margin: 20px auto;
  padding: 20px;
  /* height: 600px; */
}

.opacityli {
  color: #495057 !important;
  background-color: #c7c4c4 !important;
  /* border-color: #58c479 #2ecc71 #ffffff; */
  /* border: 2px solid #58c479; */
  border-bottom: none !important;
  border-radius: 20px !important;
}
.bgactive {
  background: #2ecc71;
  height: 75px;
  padding-top: 5px;
  border-radius: 5px;
}
.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1;
  width: 100vw;
  height: 100vh;
  background-color: #000;
}
.image--cover {
  width: 81px !important;
  height: 50px;
  border-radius: 50%;
  object-fit: cover;
  object-position: center center;
}
.btn-default {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 23px;
}

.dropdown-menu {
  position: absolute;
  z-index: 1000;
  display: none;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0;
  font-size: 14px;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
}
.headermenu {
  font-family: "Poppins";
  font-style: normal;
  font-weight: bold !important;
  font-size: 17px;
  line-height: 23px;
  text-transform: capitalize;
  color: #1c1c1c;
}
.form-body {
  text-align: left;
  padding: 20px 10px;
}

.form-body > * {
  padding: 5px;
}

.form__label {
  width: 40%;
}

.form_input {
  width: 60%;
}

.footer {
  text-align: start;
}

.react-switch-checkbox {
  height: 0;
  width: 0;
  visibility: hidden;
}

.react-switch-label {
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  width: 60px;
  height: 30px;
  background: rgba(10, 10, 10, 0.29);
  border-radius: 100px;
  position: relative;
  transition: background-color 0.2s;
}

.react-switch-label .react-switch-button {
  content: "";
  position: absolute;
  top: 2px;
  left: 2px;
  width: 25px;
  height: 25px;
  border-radius: 25px;
  transition: 0.4s;
  background: #fff;
  box-shadow: 0 0 2px 0 rgba(10, 10, 10, 0.29);
}

.react-switch-checkbox:checked + .react-switch-label .react-switch-button {
  left: calc(100% - 2px);
  transform: translateX(-100%);
}

.react-switch-label:active .react-switch-button {
  width: 40px;
}

.para {
  font-family: "Poppins", sans-serif;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  color: #3a3a3a !important;
}
.ant-form-item-label {
  font-family: "Poppins", sans-serif;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  color: #3a3a3a !important;
}
/* .upload .ant-btn{
  max-width: 40rem;
} */
/* div.ant-upload{
  width: 100%;
} */
.ant-upload-list-item {
  border-color: whitesmoke !important ;
}
.homeicon {
  color: #e9ae0a;
  float: right !important;
  height: 70px;
  width: 70px;
}
.footerHeader {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 120%;
  letter-spacing: 0.05em;
  text-transform: uppercase;

  /* Dark Black */

  color: #1c1c1c;
}

.parabold {
  color: #2c2c2c;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
}

.spanbg {
  position: relative;
  padding: 5px 14px;
  background: #58c479;
  border-radius: 5px;
  color: #fff;
}

.spanbgcircle {
  position: relative;
  padding: 9px 16px;
  background: #e9ae0a;
  border-radius: 20px;
  color: #3a3a3a;
}
.userbgcircle {
  position: relative;
  padding: 11px 14px;
  background: #58c479;
  border-radius: 20px;
  color: #fff;
}
textarea {
  resize: none;
}
.analysticimage-preview {
  width: 100%;
  height: 500px;
  background-position: center center !important;
  background: url(http://cliquecities.com/assets/no-image-e3699ae….jpg);
  background-color: #fff !important;
  background-size: cover !important;
  background-repeat: no-repeat !important;
  display: inline-block !important;
  /* box-shadow: 0px 0px 4px 1px rgb(0 0 0 / 20%); */
}
.btn-primarynca {
  display: block;
  border-radius: 0px;
  box-shadow: 0px 4px 6px 2px rgba(0, 0, 0, 0.2);
  margin-top: -5px;
}
.imgUp {
  margin-bottom: 15px;
}
.del {
  position: absolute;
  top: 0px;
  right: 15px;
  width: 30px;
  height: 30px;
  text-align: center;
  line-height: 30px;
  background-color: rgba(255, 255, 255, 0.6);
  cursor: pointer;
}
.imgAdd {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: #4bd7ef;
  color: #fff;
  box-shadow: 0px 0px 4px 1px rgb(0 0 0 / 20%);
  text-align: center;
  line-height: 30px;
  margin-top: 0px;
  cursor: pointer;
  font-size: 15px;
}
.react-switch-checkbox {
  height: 0;
  width: 0;
  visibility: hidden;
}

.react-switch-label {
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  width: 60px;
  height: 30px;
  background: rgba(10, 10, 10, 0.29);
  border-radius: 100px;
  position: relative;
  transition: background-color 0.2s;
}

.react-switch-label .react-switch-button {
  content: "";
  position: absolute;
  top: 2px;
  left: 2px;
  width: 25px;
  height: 25px;
  border-radius: 25px;
  transition: 0.4s;
  background: rgb(255, 255, 255);
  box-shadow: 0 0 2px 0 rgba(10, 10, 10, 0.29);
}

.react-switch-checkbox:checked + .react-switch-label .react-switch-button {
  left: calc(100% - 2px);
  transform: translateX(-100%);
}

.react-switch-label:active .react-switch-button {
  width: 40px;
}

.App {
  font-family: "Poppins", sans-serif;
  text-align: center;
}
.h3class {
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  line-height: 25px;
  color: #1c1c1c;
  text-align: left;
}

.cardtitle {
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  line-height: 25px;
  color: #1c1c1c;
  text-align: left;
}

.FilesDragAndDrop__area {
  width: 300px;
  height: 200px;
  padding: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: column nowrap;
  font-size: 24px;
  color: #555555;
  border: 2px #c3c3c3 dashed;
  border-radius: 12px;
}

.area__icon {
  font-size: 64px;
  margin-top: 20px;
}

/*Loading*/
.disable {
  pointer-events: none;
}

.nondisable {
  pointer-events: block;
}

/*** Spinner ***/
#spinner {
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.5s ease-out, visibility 0s linear 0.5s;
  z-index: 99999;
  background: url("../doaram/assets/da_img/logo1.png") !important;
  background-position: center !important;
  background-repeat: no-repeat !important;
}

#spinner.show {
  transition: opacity 0.5s ease-out, visibility 0s linear 0s;
  visibility: visible;
  opacity: 1 !important;
  background: url("../doaram/assets/da_img/logo1.png") !important;
  background-position: center !important;
  background-repeat: no-repeat !important;
}
.loading {
  overflow: hidden;
  position: fixed;
  z-index: 100000;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background: #e9e9e990 50% 50% no-repeat;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=70)"; /* IE 8 */
  filter: alpha(opacity=70); /* IE 5-7 */
  -moz-opacity: 0.6; /* Netscape */
  -khtml-opacity: 0.6; /* Safari 1.x */
  opacity: 0.6; /* Good browsers */
}
.loading {
  display: none;
  /* visibility: visible; */
}

.imageloader {
  position: fixed;
  top: 48%;
  left: 50%;
  width: 27%; /* bring your own prefixes */
  transform: translate(-50%, -50%);
}

/*font size*/

.font20 {
  font-size: 20px !important;
}
.font18 {
  font-size: 18px !important;
}
.font16 {
  font-size: 16px !important;
}
.font14 {
  font-size: 14px !important;
}
.font12 {
  font-size: 12px !important;
}
.font10 {
  font-size: 10px !important;
}
.font8 {
  font-size: 8px !important;
}

marquee {
  white-space: nowrap;
  height: 350px;
  /* left: 100%; */
  animation: marquee 8s linear infinite;
}
/*text color*/
.lightext {
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 29px;
  text-align: center;
  letter-spacing: 0.02em;
  color: #3a3a3a;
}
.investDashboardBodyScroll {
  height: 74vh;
  overflow-y: scroll;
  overflow-x: hidden;
}
.text-green {
  color: #19c157;
}
.text-gold {
  color: #c79f01 !important;
  font-weight: 600;
}
.bg-gold {
  background: rgb(254, 203, 0) !important;
}
.maroon {
  color: maroon;
}
.bg-pink {
  background: rgba(225, 130, 221, 0.1);
}
.light-blue {
  background: rgb(233, 247, 239) !important;
}
.light-violet {
  background: rgb(242, 243, 244) !important;
}

.circle-mt-img {
  position: relative;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  flex-shrink: 0;
  font-family: "Poppins", sans-serif;
  font-size: 1.42857rem;
  line-height: 1;
  border-radius: 50%;
  overflow: hidden;
  user-select: none;
  border: 2px solid rgb(254, 203, 0);
  width: 3rem;
  height: 3rem;
}
.slidecontainer {
  width: 100%;
}

.profile-card-4 {
  /* max-width: 300px; */
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  position: relative;
  margin: 10px auto;
  cursor: pointer;
}
.b {
  font-weight: bold !important;
}

.profile-card-2 {
  /* max-width: 300px; */
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  position: relative;
  margin: 10px auto;
  cursor: pointer;
  padding: 7px;
}

.stadbgcolor {
  background-color: #58c479;
  border: 1px solid #58c479;
  color: #fff;
}

.site-footer-inner {
  padding: 23px 0 !important;
}

.site-header {
  background: #f5f5f5;
}
.site-content {
  background: #fff;
}

.btn-secondarynca {
  background: #868686;
  border: 1px solid #868686;
}

.bg-grey {
  background: #e3e3e3;
}
/* .btn-danger {
  background: #f86868;
  border: 1px solid #f86868;
} */
.bg-yellowlight {
  background: rgba(233, 174, 10, 0.1) !important;
  color: rgb(143 106 8);
}

.bg-pinklight {
  background: rgba(225, 130, 221, 0.1) !important;
  color: rgb(77 2 73 / 77%);
}

.nav-link {
  color: #757575;
}

.circle {
  /* width: 131px; */
  /* height: 131px; */
  /* border-radius: 50%; */
  /* background: #fff; */
  /* border: 2px solid var(--bg-color); */
  /* display: flex; */
  /* justify-content: center; */
  /* align-items: center; */
  /* position: relative; */
  /* z-index: 1; */
  /* transition: all 0.3s ease-out; */
}

.circles img {
  width: 90px;
  height: 90px;
}
.circles::after {
  content: "";
  position: absolute;
  border-bottom: 1px solid #000;
  border-top: 1px solid #000;
  top: 110px;
  left: 20px;
  width: 300px;
  height: 0px;
  border: 1px solid rgba(45, 22, 33, 0.5);
}

/* ===== Scrollbar CSS ===== */
/* Firefox */
* {
  scrollbar-width: auto;
  scrollbar-color: #808080;
}

/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 16px;
}

*::-webkit-scrollbar-track {
  background: #ffffff;
}

*::-webkit-scrollbar-thumb {
  background-color: #a5a5a5;
  border-radius: 10px;
  border: 5px solid #ffffff;
}
/* end of scrollbar*/

.form-label {
  font-size: 14px !important;
}
.form-label,
.form-switch,
.form-slider {
  /* font-weight: bold !important; */
  color: #fff !important;
}
.form-control {
  font-family: "Poppins" !important;
  font-style: normal !important;
  height: 40px;
  font-weight: 500 !important;
  font-size: 14px !important;
  line-height: 21px !important;
  /* identical to box height */
  color: #2e2e2e !important;
}

.lightTxt {
  color: #9d9d9d;
  margin: 2rem 0 1.5rem;
  position: relative;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1055 !important;
  display: none;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  outline: 0;
  font-family: "Poppins", sans-serif;
}

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none;
}
/* for sm */
#switch input[type="checkbox"] {
  height: 0;
  width: 0;
  visibility: hidden;
}
ul.bullet-red {
  list-style: none;
}
ul.bullet-green {
  list-style: none;
}
ul.bullet-red li::before {
  content: "\2022"; /* Add content: \2022 is the CSS Code/unicode for a bullet */
  color: rgb(121, 4, 4); /* Change the color */
  font-weight: bold; /* If you want it to be bold */
  display: inline-block; /* Needed to add space between the bullet and the text */
  margin-left: 0em;
  font-size: 45px;
  width: 1em;
}
ul.bullet-green li::before {
  content: "\2022";
  color: green;
  font-weight: bold;
  display: inline-block;
  width: 1em;
  margin-left: 0em;
  font-size: 45px; /*Also needed for space (tweak if needed) */
}

#switch label {
  cursor: pointer;
  text-indent: -9999px;
  width: 58px;
  height: 30px;
  background: grey;
  display: block;
  border-radius: 16px;
  position: relative;
}

.lineheight1 {
  line-height: 1px;
}

#switch label:after {
  content: "";
  position: absolute;
  top: 2px;
  left: 1px;
  width: 25px;
  height: 26px;
  background: #fff;
  border-radius: 90px;
  transition: 0.3s;
}

#switch input:checked + label {
  background: #e4b21f;
}

#switch input:checked + label:after {
  left: calc(100% - 5px);
  transform: translateX(-100%);
}
output {
  font-weight: 600;
  text-align: center;
}
#switch label:active:after {
  width: 25px;
}

.modal.fade .modal-dialog {
  transition: transform 0.3s ease-out;
  transform: translate(0, -50px);
}

@media (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog {
    transition: none;
  }
}

.modal.show .modal-dialog {
  transform: none;
}

.modal.modal-static .modal-dialog {
  transform: scale(1.02);
}

.modal-dialog-scrollable {
  height: calc(100% - 1rem);
}

.modal-dialog-scrollable .modal-content {
  max-height: 100%;
  overflow: hidden;
}

.modal-dialog-scrollable .modal-body {
  overflow-y: auto;
}

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - 1rem);
}

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0;
  padding: 0 !important;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1;
  width: 100vw;
  height: 100vh;
  background-color: #000;
}

.modal-backdrop.fade {
  opacity: 0;
}

.modal-backdrop.show {
  opacity: 0.5;
}

.modal-header {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: 1px solid #dee2e6;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px);
}

.modal-header .btn-close {
  padding: 0.5rem 0.5rem;
  margin: -0.5rem -0.5rem -0.5rem auto;
}

.modal-title {
  margin-bottom: 0;
  line-height: 1.5;
}
.alertModelContent {
  max-width: 100%;
  width: 40%;
}
.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem;
}

.modal-footer {
  display: flex;
  flex-wrap: wrap;
  flex-shrink: 0;
  align-items: center;
  justify-content: flex-end;
  padding: 0.75rem;
  border-top: 1px solid #dee2e6;
  border-bottom-right-radius: calc(0.3rem - 1px);
  border-bottom-left-radius: calc(0.3rem - 1px);
}

.modal-footer > * {
  margin: 0.25rem;
}

@media (max-width: 992px) {
  .signupimg {
    display: none;
  }
}
@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto;
  }
  .modal-dialog-scrollable {
    height: calc(100% - 3.5rem);
  }
  .modal-dialog-centered {
    min-height: calc(100% - 3.5rem);
  }
  .modal-sm {
    max-width: 300px;
  }
}
@media (min-width: 992px) {
  .modal-lg,
  .modal-xl {
    max-width: 800px;
  }
}
@media (min-width: 1200px) {
  .modal-xl {
    max-width: 1140px;
  }
}
.modal-fullscreen {
  width: 100vw;
  max-width: none;
  height: 100%;
  margin: 0;
}
.modal-fullscreen .modal-content {
  height: 100%;
  border: 0;
  border-radius: 0;
}
.modal-fullscreen .modal-header {
  border-radius: 0;
}
.modal-fullscreen .modal-body {
  overflow-y: auto;
}
.modal-fullscreen .modal-footer {
  border-radius: 0;
}
@media (max-width: 575.98px) {
  .modal-fullscreen-sm-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-sm-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-sm-down .modal-header {
    border-radius: 0;
  }
  .modal-fullscreen-sm-down .modal-body {
    overflow-y: auto;
  }
  .modal-fullscreen-sm-down .modal-footer {
    border-radius: 0;
  }
}
@media (max-width: 767.98px) {
  .modal-fullscreen-md-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-md-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-md-down .modal-header {
    border-radius: 0;
  }
  .modal-fullscreen-md-down .modal-body {
    overflow-y: auto;
  }
  .modal-fullscreen-md-down .modal-footer {
    border-radius: 0;
  }
}
@media (max-width: 991.98px) {
  .modal-fullscreen-lg-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-lg-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-lg-down .modal-header {
    border-radius: 0;
  }
  .modal-fullscreen-lg-down .modal-body {
    overflow-y: auto;
  }
  .modal-fullscreen-lg-down .modal-footer {
    border-radius: 0;
  }
}
@media (max-width: 1199.98px) {
  .modal-fullscreen-xl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }

  .modal-fullscreen-xl-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }

  .modal-fullscreen-xl-down .modal-header {
    border-radius: 0;
  }

  .modal-fullscreen-xl-down .modal-body {
    overflow-y: auto;
  }

  .modal-fullscreen-xl-down .modal-footer {
    border-radius: 0;
  }
}
@media (max-width: 1399.98px) {
  .modal-fullscreen-xxl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-xxl-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }

  .modal-fullscreen-xxl-down .modal-header {
    border-radius: 0;
  }

  .modal-fullscreen-xxl-down .modal-body {
    overflow-y: auto;
  }

  .modal-fullscreen-xxl-down .modal-footer {
    border-radius: 0;
  }
}
.navbar .navbar {
  padding: 5px;
  margin-bottom: 0rem;
}
.card {
  z-index: 0;
  border: none;
  position: relative;
  border-radius: 4px;
  background: #fff;
  box-shadow: 0 6px 10px rgba(0, 0, 0, 0.08), 0 0 6px rgba(0, 0, 0, 0.05);
  transition: 0.3s transform cubic-bezier(0.155, 1.105, 0.295, 1.12),
    0.3s box-shadow,
    0.3s -webkit-transform cubic-bezier(0.155, 1.105, 0.295, 1.12);
  /* padding: 14px 80px 18px 36px; */
  cursor: pointer;
}

/* assdf */
#myCauseTab .card:hover {
  transform: scale(1.05);
  box-shadow: 0px 27px 20px -9px rgba(0, 0, 0, 0.16);
  border-radius: 5px;
}

.dropdown .dropdown-menu {
  border-radius: 0;
}
#deals-slider {
  margin-top: 2px;
}
.post-slidenca {
  background: #fff;
  margin: 20px 15px 20px;
  border-radius: 15px;
  padding-top: 1px;
  box-shadow: 0px 14px 22px -9px #bbcbd8;
}
.post-slidenca .post-img {
  position: relative;
  overflow: hidden;
  border-radius: 10px;
  margin: -12px 15px 8px 15px;
  margin-left: -10px;
}
.post-slidenca .post-img img {
  width: 100%;
  height: auto;
  transform: scale(1, 1);
  transition: transform 0.2s linear;
}
.post-slidenca:hover .post-img img {
  transform: scale(1.1, 1.1);
}
.post-slidenca .over-layer {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  background: linear-gradient(
    -45deg,
    rgba(6, 190, 244, 0.75) 0%,
    rgba(45, 112, 253, 0.6) 100%
  );
  transition: all 0.5s linear;
}
.post-slidenca:hover .over-layer {
  opacity: 1;
  text-decoration: none;
}
.post-slidenca .over-layer i {
  position: relative;
  top: 45%;
  text-align: center;
  display: block;
  color: #fff;
  font-size: 25px;
}
.post-slidenca .post-content {
  background: #fff;
  padding: 2px 20px 40px;
  border-radius: 15px;
}
.post-slidenca .post-title a {
  font-size: 15px;
  font-weight: bold;
  color: #333;
  display: inline-block;
  text-transform: uppercase;
  transition: all 0.3s ease 0s;
}
.post-slidenca .post-title a:hover {
  text-decoration: none;
  color: #3498db;
}
.post-slidenca .post-description {
  line-height: 24px;
  color: #808080;
  margin-bottom: 25px;
}
.post-slidenca .post-date {
  color: #a9a9a9;
  font-size: 14px;
}
.post-slidenca .post-date i {
  font-size: 20px;
  margin-right: 8px;
  color: #cfdace;
}
.post-slidenca .read-more {
  padding: 7px 20px;
  float: right;
  font-size: 12px;
  background: #2196f3;
  color: #ffffff;
  box-shadow: 0px 10px 20px -10px #1376c5;
  border-radius: 25px;
  text-transform: uppercase;
}
.post-slidenca .read-more:hover {
  background: #3498db;
  text-decoration: none;
  color: #fff;
}
@media only screen and (max-width: 1280px) {
  .post-slidenca .post-content {
    padding: 0px 15px 25px 15px;
  }
}
.eclipse_wrap {
  overflow: hidden;
  max-width: 12ch;
  text-overflow: ellipsis;
  white-space: nowrap;
}
#msform {
  text-align: center;
  position: relative;
}
#msform fieldset {
  /* background: rgb(214, 210, 210); */
  border: 0 none;
  border-radius: 0.5rem;
  box-sizing: border-box;
  width: 100%;
  margin-left: 15px;
  padding-bottom: 20px;
  position: relative;
}

#msform fieldset:not(:first-of-type) {
  display: none;
}
#msform input:focus,
#msform textarea:focus {
  -moz-box-shadow: none !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  border: 1px solid #58c479;
  outline-width: 0;
}

#msform .action-button {
  width: 100px;
  background: #58c479;
  font-weight: bold;
  color: white;
  border: 0 none;
  border-radius: 0px;
  cursor: pointer;
  padding: 10px 5px;
  margin: 10px 0px 10px 5px;
  float: right;
}

#msform .action-button:hover,
#msform .action-button:focus {
  background-color: #311b92;
}

#msform .action-button-previous {
  width: 100px;
  background: #616161;
  font-weight: bold;
  color: white;
  border: 0 none;
  border-radius: 0px;
  cursor: pointer;
  padding: 10px 5px;
  margin: 10px 5px 10px 0px;
  float: right;
}

#msform .action-button-previous:hover,
#msform .action-button-previous:focus {
  background-color: #000000;
}
.purple-text {
  color: #58c479;
  font-weight: normal;
}
.fs-title {
  font-size: 25px;
  color: #58c479;
  margin-bottom: 15px;
  font-weight: normal;
  text-align: left;
}

.form-card {
  text-align: left;
}
.card-01 .badge-box {
  position: absolute;
  top: 492px;
  left: 10%;
  width: 200px;
  height: 200px;
  margin-left: -50px;
  text-align: left;
}
.card-01 .badge-box img {
  background: #006eff;
  color: #fff;
  border-radius: 50%;
  /* width: 261px; */
  height: 200px;
  line-height: 50px;
  text-align: center;
  font-size: 20px;
}

.category {
  font-family: "Poppins" !important;
  font-style: normal;
  font-weight: 500;
  font-size: 12px !important;
  line-height: 7px;
  display: flex;
  align-items: center;
  color: #808080;
}
.heading {
  font-size: 16px !important;
  font-family: "Poppins" !important;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  display: flex;
  align-items: center;
  color: #3e3e3e;
}
#heading {
  text-transform: uppercase;
  color: #58c479;
  font-weight: normal;
}
.color input {
  background-color: #f1f1f1;
}
.public-DraftStyleDefault-ltr {
  padding: 30px;
}
/*File uypload*/
#aks-file-upload {
  width: 310px;
  display: block;
  margin: 0 auto;
  margin-top: 4rem;
}
#uploadfile {
  width: 80%;
  margin: 0 auto;
  color: #002c7b;
  line-height: 1.5;
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.input-group .btn {
  position: relative;
  z-index: 1;
}
.files input:focus {
  outline: 2px dashed #92b0b3;
  outline-offset: -10px;
  -webkit-transition: outline-offset 0.15s ease-in-out,
    background-color 0.15s linear;
  transition: outline-offset 0.15s ease-in-out, background-color 0.15s linear;
  border: 1px solid #92b0b3;
}

.files:before {
  position: absolute;
  bottom: 10px;
  left: 0;
  pointer-events: none;
  width: 100%;
  right: 0;
  height: 57px;
  content: " or drag it here. ";
  display: block;
  margin: 0 auto;
  color: #020202;
  font-weight: 600;
  text-transform: capitalize;
  text-align: center;
}
.files input {
  outline: 2px dashed #92b0b3;
  outline-offset: -10px;
  -webkit-transition: outline-offset 0.15s ease-in-out,
    background-color 0.15s linear;
  transition: outline-offset 0.15s ease-in-out, background-color 0.15s linear;
  padding: 120px 0px 85px 35%;
  text-align: center !important;
  margin: 0;
  width: 100% !important;
}
.files {
  position: relative;
}
.files:after {
  pointer-events: none;
  position: absolute;
  top: 60px;
  left: 0;
  width: 50px;
  right: 0;
  height: 56px;
  content: "";
  background-image: url(https://image.flaticon.com/icons/png/128/109/109612.png);
  display: block;
  margin: 0 auto;
  background-size: 100%;
  background-repeat: no-repeat;
}
.single-cta i {
  color: #ff5e14;
  font-size: 30px;
  float: left;
  margin-top: 8px;
}
.cta-text {
  padding-left: 15px;
  display: inline-block;
}
.cta-text h4 {
  color: rgb(68, 68, 68);
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 2px;
}
.cta-text span {
  color: rgb(68, 68, 68);
  font-size: 15px;
}
.facebook-bg {
  background: #3b5998;
}
.twitter-bg {
  background: #55acee;
}
.google-bg {
  background: #dd4b39;
}
.copyright-area {
  background: #202020;
  padding: 25px 0;
}
.copyright-text p {
  margin: 0;
  font-size: 14px;
  color: #878787;
}
.copyright-text p a {
  color: #ff5e14;
}

.subscribe-form {
  position: relative;
  overflow: hidden;
}
.subscribe-form input {
  width: 100%;
  padding: 14px 28px;
  background: #2e2e2e;
  border: 1px solid #2e2e2e;
  color: #fff;
}
.subscribe-form button {
  position: absolute;
  right: 0;
  background: #ff5e14;
  padding: 13px 20px;
  border: 1px solid #ff5e14;
  top: 0;
}
.subscribe-form button i {
  color: #fff;
  font-size: 22px;
  transform: rotate(-6deg);
}

form {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  color: #2e2e2e;
}
.btn__badge {
  background: #ff5d5d;
  color: white;
  font-size: 9px;
  position: absolute;
  top: 0;
  right: 0px;
  padding: 3px 10px;
  border-radius: 50%;
}

.fit-image {
  width: 100%;
  object-fit: cover;
}

.pulse-button {
  box-shadow: 0 0 0 0 rgba(255, 0, 0, 0.5);
  -webkit-animation: pulse 1.5s infinite;
}

.pulse-button:hover {
  -webkit-animation: none;
}

/* select2 css https://apalfrey.github.io/select2-bootstrap-5-theme/examples/validation/    */
.select2-container--bootstrap-5
  .select2--small.select2-dropdown
  .select2-results__options
  .select2-results__option,
.select2-container--bootstrap-5
  .select2--small.select2-dropdown
  .select2-search
  .select2-search__field {
  padding: 5px 16px;
  font-size: 14px;
}

.select2-container--bootstrap-5 .select2-selection {
  font-size: 12px;
}

.select2-container--bootstrap-5
  .select2-selection--multiple
  .select2-selection__rendered
  .select2-selection__choice {
  font-size: 12px;
}
.form-select-sm
  .select2-container--bootstrap-5
  .select2-selection--multiple
  .select2-selection__rendered
  .select2-selection__choice {
  padding: 0.35em 0.65em;
  font-size: 12px;
}
.platinum-color {
  color: #e5e4e2; /* Platinum color code */
}

.containerslider {
  overflow: hidden;
}
.containerslider .logoslider {
  /* animation: slidein 30s linear infinite; */
  white-space: nowrap;
  width: 35%;
}
.containerslider .logos {
  width: 80%;
  display: inline-block;
  margin: 0px 0;
}
.containerslider .fab {
  width: calc(100% / 5);
  animation: fade-in 0.5s cubic-bezier(0.455, 0.03, 0.515, 0.955) forwards;
}

@keyframes slidein {
  from {
    transform: translate3d(0, 0, 0);
  }
  to {
    transform: translate3d(-100%, 0, 0);
  }
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

#signupselector {
  /* margin-bottom: 30px; */
  overflow: hidden;
  color: lightgrey;
}

#signupselector .active {
  color: #af9306;
}

#signupselector li {
  list-style-type: none;
  font-size: 12px;
  width: 25%;
  float: left;
  position: relative;
  font-weight: 400;
}

#signupselector #account:before {
  font-family: FontAwesome;
  content: "\f0f6 ";
}

#signupselector #personal:before {
  font-family: FontAwesome;
  content: "\f10b";
}

#signupselector #payment:before {
  font-family: FontAwesome;
  content: "\f2bb";
}

#signupselector #confirm:before {
  font-family: FontAwesome;
  content: "\f09d";
}

#signupselector li:before {
  width: 50px;
  height: 50px;
  line-height: 45px;
  display: block;
  font-size: 20px;
  color: #ffffff;
  background: lightgray;
  border-radius: 50%;
  margin: 0 auto 10px auto;
  padding: 2px;
}

#signupselector li:after {
  content: "";
  width: 100%;
  height: 2px;
  background: lightgray;
  position: absolute;
  left: 0;
  top: 25px;
  z-index: -1;
}

#signupselector li.active:before,
#signupselector li.active:after {
  background: #e7c41a;
}
.fieldlabels {
  color: gray;
  text-align: left;
}

.steps {
  font-size: 12px;
  color: gray;
  margin-bottom: 10px;
  font-weight: bold;
  text-align: right;
}
.userproIcon {
  background: url("../doaram/assets/da_img/usericon.png");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 21px 21px;
  text-align: center;
  height: 30px;
  width: 30px;
  padding-left: 6px;
  line-height: 1.5;
  left: 1rem;
  display: grid;
}

.btnG {
  display: flex !important;
  justify-content: center;
}

.btnG p {
  margin: 0;
  line-height: 1.8;
  font-size: 16px;
}
.btnG p :hover {
  box-shadow: none;
}
.none {
  display: none;
}
/* ================== Badge Overlay CSS ========================*/
.badge-overlay {
  position: absolute;
  left: 0%;
  top: 0px;
  width: 100%;
  height: 100%;
  overflow: hidden;
  pointer-events: none;
  z-index: 100;
  -webkit-transition: width 1s ease, height 1s ease;
  -moz-transition: width 1s ease, height 1s ease;
  -o-transition: width 1s ease, height 1s ease;
  transition: width 0.4s ease, height 0.4s ease;
}
/* ================== Badge CSS ========================*/
.badge {
  margin: 0;
  padding: 0;
  color: white;
  padding: 10px 10px;
  font-size: 15px;
  font-family: "Poppins", sans-serif;
  text-align: center;
  line-height: normal;
  text-transform: uppercase;
}

.badge::before,
.badge::after {
  content: "";
  position: absolute;
  top: 0;
  margin: 0 -1px;
  width: 100%;
  height: 100%;
  /* background: inherit; */
  min-width: 55px;
}
.badge::before {
  right: 100%;
}
.badge::after {
  left: 100%;
}
/* ================== Badge color CSS ========================*/
.badge.yellow {
  background-image: linear-gradient(7deg, #e3c714, #f7d603);
}
.badge.grey {
  background-image: linear-gradient(7deg, #818181, #bfbfbf);
}
.button-white {
  border: 1px solid #f1e6a5 !important;
  background-color: #fffbe5 !important;
}
.button-white :hover {
  box-shadow: 4px 5px 7px #efefef !important;
}
.buttonst {
  /* border: none; */
  background: #d69228;
  background: linear-gradient(90deg, #ffba4c 0%, #d69228 100%);
  color: #fff;
  overflow: hidden;
}
.buttonst:hover {
  text-decoration: none;
  color: #fff;
}
.buttonst::before {
  position: absolute;
  content: "";
  display: inline-block;
  top: -180px;
  left: 0;
  width: 30px;
  height: 100%;
  background-color: #fff;
  animation: ring 2s ease-in-out infinite;
}
.buttonst:hover {
  opacity: 0.7;
}
.buttonst:active {
  box-shadow: 4px 4px 6px 0 rgba(255, 255, 255, 0.3),
    -4px -4px 6px 0 rgba(116, 125, 136, 0.2),
    inset -4px -4px 6px 0 rgba(255, 255, 255, 0.2),
    inset 4px 4px 6px 0 rgba(0, 0, 0, 0.2);
}
@keyframes ring {
  0% {
    -webkit-transform: scale(0) rotate(45deg);
    opacity: 0;
  }
  80% {
    -webkit-transform: scale(0) rotate(45deg);
    opacity: 0.5;
  }
  81% {
    -webkit-transform: scale(4) rotate(45deg);
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(50) rotate(45deg);
    opacity: 0;
  }
}
/* ================== Badge Position CSS ========================*/
.top-left {
  position: absolute;
  top: 0;
  left: 0;
  -ms-transform: translateX(-30%) translateY(0%) rotate(-45deg);
  -webkit-transform: translateX(-30%) translateY(0%) rotate(-45deg);
  transform: translateX(-30%) translateY(0%) rotate(-45deg);
  -ms-transform-origin: top right;
  -webkit-transform-origin: top right;
  transform-origin: top right;
}
.top-right {
  position: absolute;
  top: 0;
  right: 0;
  -ms-transform: translateX(30%) translateY(0%) rotate(45deg);
  -webkit-transform: translateX(30%) translateY(0%) rotate(45deg);
  transform: translateX(30%) translateY(0%) rotate(45deg);
  -ms-transform-origin: top left;
  -webkit-transform-origin: top left;
  transform-origin: top left;
}

.bottom-left {
  position: absolute;
  bottom: 0;
  left: 0;
  -ms-transform: translateX(-30%) translateY(0%) rotate(45deg);
  -webkit-transform: translateX(-30%) translateY(0%) rotate(45deg);
  transform: translateX(-30%) translateY(0%) rotate(45deg);
  -ms-transform-origin: bottom right;
  -webkit-transform-origin: bottom right;
  transform-origin: bottom right;
}

.bottom-right {
  position: absolute;
  bottom: 0;
  right: 0;
  -ms-transform: translateX(30%) translateY(0%) rotate(-45deg);
  -webkit-transform: translateX(30%) translateY(0%) rotate(-45deg);
  transform: translateX(30%) translateY(0%) rotate(-45deg);
  -ms-transform-origin: bottom left;
  -webkit-transform-origin: bottom left;
  transform-origin: bottom left;
}

.top-full {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  text-align: center;
}

.middle-full {
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  text-align: center;
  -ms-transform: translateX(0%) translateY(-50%) rotate(0deg);
  -webkit-transform: translateX(0%) translateY(-50%) rotate(0deg);
  transform: translateX(0%) translateY(-50%) rotate(0deg);
}
.sidebar {
  overflow-x: hidden !important;
  overflow-y: scroll !important;
  height: 80vh !important;
}
.bottom-full {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  text-align: center;
}
.nav-sidebar > .nav-item .nav-icon {
  margin-right: 0.3rem;
}
.causehis {
  font-family: "Poppins" !important;
  font-style: normal;
  font-weight: 500;
  font-size: 12px !important;
  line-height: 18px;
  /* identical to box height */
  align-items: center;
  color: #808080;
}
/* table */
.pricingTable {
  color: var(--main-color);
  background: #fff;
  font-family: "Poppins", sans-serif;
  text-align: center;
  padding: 20px 0 30px;
  margin: 0 15px;
  margin-bottom: 10px;
  height: 820px;
}
.pricingTable .pricingTable-header {
  margin: 0 0 25px;
}
.pricingTable .title {
  color: #fff;
  background: var(--main-color);
  font-size: 16px;
  font-weight: 600;
  text-transform: uppercase;
  padding: 5px 5px;
  margin: 0 -15px 15px;
  border-radius: 5px 5px 5px 0;
  position: relative;
}
.pricingTable .title:after {
  content: "";
  background: linear-gradient(to top right, transparent 50%, #444 50%);
  height: 15px;
  width: 15px;
  position: absolute;
  left: 0;
  top: 100%;
}
.pricingTable .price-value {
  padding: 0 0 0 70px;
  display: inline-block;
  position: relative;
}
.pricingTable .price-value span {
  display: block;
}
.pricingTable .price-value .price-currency {
  color: #fff;
  background-color: var(--main-color);
  font-size: 16px;
  line-height: 40px;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  position: absolute;
  left: 0;
  top: 5px;
}
.pricingTable .price-value .amount {
  color: #000;
  font-size: 35px;
  font-weight: 200;
  line-height: 45px;
  padding: 0 20px;
  margin: 0 0 3px;
  border-bottom: 2px solid var(--main-color);
}
.pricingTable .price-value .duraton {
  color: #666;
  font-size: 15px;
  font-weight: 300;
}
.pricingTable .pricing-content {
  text-align: left;
  padding: 0;
  margin: 0 0px 25px;
  list-style: none;
  display: inline-block;
}
.pricingTable .pricing-content li {
  color: black;
  font-size: 17px;
  font-weight: 300;
  line-height: 20px;
  letter-spacing: 0.5px;
  padding: 0 15px 0 15px;
  margin: 0px 0 18px;
  position: relative;
}
.pricingTable .pricing-content li:last-child {
  margin: 0;
}
.pricingTable .pricing-content li:before {
  /* content: ""; */
  background-color: var(--main-color);
  height: 10px;
  width: 10px;
  border-radius: 50%;
  transform: translateY(-50%);
  position: absolute;
  left: 0;
  top: 50%;
}
.pricingTable .pricing-content li.disable:before {
  background-color: #999;
}
.pricingTable .pricingTable-signup a {
  color: #fff;
  background: var(--main-color);
  font-size: 23px;
  font-weight: 400;
  text-transform: uppercase;
  padding: 5px 25px;
  border-radius: 5px;
  display: inline-block;
  transition: all 0.3s ease 0s;
}
.pricingTable .pricingTable-signup a:hover {
  text-shadow: -2px 2px 1px rgba(0, 0, 0, 0.4);
  box-shadow: 5px 5px 0 rgba(0, 0, 0, 0.12);
}

.pricingTable.pink {
  --main-color: #694155;
}
.pricingTable.golden {
  --main-color: #d2af26;
}
.pricingTable.blue {
  --main-color: #0038a1;
}
.pricingTable.bronze {
  --main-color: #cd7f32;
}
.pricingTable.silver {
  --main-color: #6a737b;
}
.pricingTable.platinum {
  --main-color: #008eaa;
}
@media only screen and (max-width: 990px) {
  .pricingTable {
    margin: 0 15px 40px;
  }
}
/* Tooltip bestjquery.com/*/
.demo {
  text-align: center;
}
.toolTip {
  text-align: center;
  color: #240b36;
  font-size: 12px;
  font-weight: 500;
  cursor: pointer;
  display: inline-block;
  position: relative;
  z-index: 10000;
}
.toolTip:before,
.toolTip:after {
  content: "";
  background: linear-gradient(to right bottom, transparent 50%, #694155 51%);
  height: 20px;
  width: 20px;
  opacity: 0;
  z-index: -100;
  transition: all 0.65s cubic-bezier(0.84, -0.18, 0.31, 1.26),
    opacity 0.65s 0.5s;
}
.toolTip:after {
  content: attr(data-tip);
  color: #fff;
  background: linear-gradient(90.15deg, #694155 0.12%, #bb7397 99.87%);
  font-size: 14px;
  font-weight: 500;
  height: auto;
  width: 200px;
  padding: 7px 10px;
  border-radius: 10px;
  transition: all 0.65s cubic-bezier(0.84, -0.18, 0.31, 1.26) 0.2s;
}
.toolTip:hover:before,
.toolTip:hover:after {
  opacity: 1;
  z-index: 100;
  transition: all 0.65s cubic-bezier(0.84, -0.18, 0.31, 1.26) 0.2s;
}
.toolTip:hover:after {
  transition: all 0.65s cubic-bezier(0.84, -0.18, 0.31, 1.26);
}
.toolTip.top:before,
.toolTip.top:after {
  transform: translateX(-50%) translateY(-60%) rotate(45deg) scale(0.6);
  position: absolute;
  left: 50%;
  bottom: 100%;
}
.toolTip.top:after {
  transform: translateX(-50%) translateY(20%) rotate(0) scale(0.6);
}
.toolTip.top:hover:before {
  transform: translateX(-50%) translateY(-35%) rotate(45deg) scale(1);
}
.toolTip.top:hover:after {
  transform: translateX(-50%) translateY(-20%) rotate(0) scale(1);
}
.toolTip.right:before,
.toolTip.right:after {
  transform: translateX(80%) translateY(50%) rotate(135deg) scale(1);
  position: absolute;
  left: 100%;
  bottom: 50%;
}
.toolTip.right:after {
  transform: translateX(-30%) translateY(50%) rotate(0) scale(0.6);
}
.toolTip.right:hover:before {
  transform: translateX(40%) translateY(50%) rotate(135deg) scale(1);
}
.toolTip.right:hover:after {
  transform: translateX(7%) translateY(50%) rotate(0) scale(1);
}
@media only screen and (max-width: 767px) {
  .toolTip {
    margin: 0 0 20px;
  }
}
/***/

/*Timelines*/
.timeline_area {
  position: relative;
  z-index: 1;
}
.single-timeline-area {
  position: relative;
  z-index: 1;
  padding-left: 180px;
}
@media only screen and (max-width: 575px) {
  .single-timeline-area {
    padding-left: 100px;
  }
}
.single-timeline-area .timeline-date {
  position: absolute;
  width: 180px;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -ms-grid-row-align: center;
  align-items: center;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  padding-right: 60px;
}
@media only screen and (max-width: 575px) {
  .single-timeline-area .timeline-date {
    width: 100px;
  }
}
.single-timeline-area .timeline-date::after {
  position: absolute;
  width: 3px;
  height: 100%;
  content: "";
  background-color: #ebebeb;
  top: 0;
  right: 30px;
  z-index: 1;
}
.single-timeline-area .timeline-date::before {
  position: absolute;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  content: "";
  top: 0%;
  right: 12px;
  z-index: 5;
  margin-top: -5.5px;
}
.timeline-date.green::before {
  background-color: #047a41;
}
.timeline-date.yellow::before {
  background-color: #f1c40f;
}
.timeline-date.grey::before {
  background-color: #4e4d4d;
}
.single-timeline-area .timeline-date p {
  margin-bottom: 0;
  color: #020710;
  font-size: 11px;
  text-transform: uppercase;
  font-weight: bold;
  background: #ececec;
  padding: 9px;
  border-radius: 3px;
  text-align: right;
}
.single-timeline-area .single-timeline-content {
  position: relative;
  z-index: 1;
  padding: 30px 30px 25px;
  border-radius: 6px;
  margin-bottom: 15px;
  margin-top: 15px;
  -webkit-box-shadow: 0 0.25rem 1rem 0 rgba(47, 91, 234, 0.125);
  box-shadow: 0 0.25rem 1rem 0 rgba(47, 91, 234, 0.125);
  border: 1px solid #ebebeb;
  background: #fff;
}
@media only screen and (max-width: 575px) {
  .single-timeline-area .single-timeline-content {
    padding: 20px;
  }
}
.single-timeline-area .single-timeline-content .timeline-icon {
  -webkit-transition-duration: 500ms;
  transition-duration: 500ms;
  width: 30px;
  height: 30px;
  background-color: #f1c40f;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 30px;
  flex: 0 0 30px;
  text-align: center;
  max-width: 30px;
  border-radius: 50%;
  margin-right: 15px;
}
.single-timeline-area .single-timeline-content .timeline-icon i {
  color: #ffffff;
  line-height: 30px;
}
.single-timeline-area .single-timeline-content .timeline-text h6 {
  -webkit-transition-duration: 500ms;
  transition-duration: 500ms;
}
.single-timeline-area .single-timeline-content .timeline-text p {
  font-size: 13px;
  margin-bottom: 0;
}
.single-timeline-area .single-timeline-content:hover .timeline-icon,
.single-timeline-area .single-timeline-content:focus .timeline-icon {
  background-color: #020710;
}
.single-timeline-area .single-timeline-content:hover .timeline-text h6,
.single-timeline-area .single-timeline-content:focus .timeline-text h6 {
  color: #3f43fd;
}
/* small timeline part css*/
.hori-timeline .events {
  border-top: 3px solid #e9ecef;
}
.hori-timeline .events .event-list {
  display: block;
  position: relative;
  text-align: center;
  padding-top: 7px;
  margin-right: 0;
}
.hori-timeline .events .event-list:before {
  content: "";
  position: absolute;
  height: 36px;
  border-right: 2px dashed #dee2e6;
  top: 0;
}
.hori-timeline .events .event-list .event-date {
  position: absolute;
  top: 38px;
  left: 0;
  right: 0;
  width: 20px;
  margin: 0 auto;
  border-radius: 12px;
  padding: 2px 4px;
}
@media (min-width: 1140px) {
  .hori-timeline .events .event-list {
    display: inline-block;
    width: 24%;
    padding-top: 7px;
  }
  .hori-timeline .events .event-list .event-date {
    top: -12px;
  }
}
/*end*/

.bg-goldweb {
  /* background: rgb(254, 203, 0) !important; */
  background: linear-gradient(275.07deg, #f2bf35 -0.15%, #d69228 100%);
}
/* ak overwrite */
.form-floating {
  background-color: white;
  max-height: 61px !important;
}
input:focus,
input.form-control:focus {
  outline: none !important;
  outline-width: 0 !important;
  box-shadow: none;
  -moz-box-shadow: none;
  -webkit-box-shadow: none;
}
.loginbtn {
  height: 45px;
  font-size: larger;
  border: none;
  min-width: 144px;
  color: white !important;
}
.btnborder {
  border: none;
  min-height: 35px;
  color: white;
}

.nav-linknca {
  font-size: 12px !important;
  font-weight: 300 !important;
}
.nav-linkheadernca {
  font-size: 20px !important;
  color: black;
}
.nav-linkheadernca:hover {
  color: #000;
}
.rightbarnca {
  z-index: 100 !important;
}
/* button.accordion-button{
  color:#Af3700 !important;
} */
div.accordion-body {
  color: #000000 !important;
}
.accordion-button {
  padding: 1rem 1.25rem !important;
  font-size: 15px !important;
  color: #5f656f !important;
  background-color: #fff !important;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out,
    border-radius 0.15s ease;
}
/* Web home page accordion work */
.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: #ffffff !important;
  background: linear-gradient(275.07deg, #f2bf35 -0.15%, #d69228 100%);
  border-color: #ff9800 #ffbf00 #fff;
}
.nav-tabs .nav-link {
  margin-bottom: -1px;
  /* background: none; */
  border: 1px solid transparent;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
}
.fw-web {
  font-weight: 600 !important;
}

.cardStyle {
  border-radius: 22px !important;
}
.navbar .navbar-nav .nav-link:hover,
.navbar .navbar-nav .nav-link.active {
  color: #ffd289 !important;
  text-decoration: underline !important;
  text-decoration-color: #ffb43b !important;
  text-decoration-thickness: 0.125em !important;
  text-underline-offset: 5.5px !important;
}
.signin .ant-form-item,
.signup .ant-form-item {
  margin-bottom: 4px !important;
}
.signin .ant-form-item-label,
.signup .ant-form-item-label {
  padding-bottom: 6px !important;
  margin-top: 9px;
  margin-left: 8px;
}
.loginas-span {
  float: left;
  margin-left: 8px;
}
.rememberme {
  font-weight: 600 !important;
}
.login .ant-form-item-control-input-content {
  max-width: 75px !important;
}
.emailver {
  display: flex !important;
  align-items: center !important;
}
.sucessiconimg {
  height: 50px !important;
  width: 50px !important;
}
.alignimagecenter {
  width: 300px;
  height: 280px;
  margin-top: 130px;
}
.homeimagetop {
  border-top-right-radius: 10px !important;
  border-top-left-radius: 10px !important;
}

.homeimageleftbottom {
  border-bottom-left-radius: 10px !important;
}

.homeimagerightbottom {
  border-bottom-right-radius: 10px !important;
}
.blogheight {
  height: 270px !important;
}

/* arun css */
.glasscard {
  justify-content: center !important;
  background-color: rgba(255, 255, 255, 0);
  box-shadow: 0px 8px 32px 0 rgba(100, 0, 100, 0.3);
  border-radius: 16px;
  padding: 0px 16px 16px 16px;
  backdrop-filter: blur(3px);
}
.paymentglasscard {
  justify-content: center !important;
  box-shadow: 0px 3px 5px 0;
  background-color: whitesmoke !important;
  border-radius: 16px;
  padding: 0px 16px 16px 16px;
}
.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #d89529 !important;
  font-weight: 500;
}
.tab-pane {
  max-height: 100vh;
  /* overflow-x: scroll; */
}
.antsidebar {
  background-color: #2d1621 !important;
}
.ant-layout-sider-trigger {
  background-color: #2d1621 !important;
}
.ant-menu-item-selected {
  background: linear-gradient(
    90.15deg,
    #694155 0.12%,
    #bb7397 99.87%
  ) !important; /* Change this to the color you want */
}
.antsidebar .ant-menu-sub {
  background-color: #2d1621 !important;
}
.homebtnheight {
  height: 28px;
}
@media (max-width: 768px) {
  .collapse-button {
    display: none !important;
  }
}
.navbar .navbar-nav .nav-link {
  margin-right: 17px !important;
  padding: 10px 10px !important;
  color: black !important;
  font-size: 14px !important;
  font-weight: 500 !important;
  outline: none !important;
}
@media screen and (max-width: 1226px) {
  .donate-btn{
 visibility: hidden;
  }
   }
html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: transparent;
}
body {
  margin: 0;
  font-family: "Montserrat" !important;
  font-size: 12px !important;
  font-weight: 400 !important;
  line-height: 1.5 !important;
  color: #212529;
  text-align: left;
  background-color: #fff;
  /* overflow-x: hidden; */
}
#toast-container .toast-warning {
  background-color: #ffc107;
}
label:not(.form-check-label):not(.custom-file-label) {
  font-weight: 700;
  font-size: 12px;
}
.list-unstyled {
  list-style-type: none;
}
.pricing-content {
  list-style-type: none;
  padding-left: 0;
}
ul.pricing-content {
  list-style-type: none;
}
.homeupgradeimg {
  max-height: 400px;
  max-width: 600px;
}
.ant-upload-list-item-info .ant-upload-list-item-name {
  color: black; /* Set your desired text color */
}
.upgradebg {
  background-color: #f0f0f0;
}
.upgradepricingtable {
  background: rgba(255, 255, 255, 0.2);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  border: 1px solid rgba(255, 255, 255, 0.3);
}
.custom-list {
  max-width: 500px;
  margin: 0 auto;
  padding-left: 20px;
}
.custom-list li {
  list-style-type: disc; /* Adding bullet style */
  margin-bottom: 5px;
  color: black; /* Adjust spacing between items */
}
/* Custom CSS to remove vertical borders */
.ant-table-thead > tr > th {
  border-right: none !important;
}

.ant-table-tbody > tr > td {
  border-right: none !important;
}
/* Added by Thulasy 25.12.2023 */
.filster-left {
  position: absolute;
  bottom: 8px;
  left: 50%;
  transform: translate(-50%, -50%);
  font-weight: bolder;
  background: #00000063;
  padding: 20px;
  border-radius: 4px;
  color: #fff;
}
.bd-callout-warning {
  border-left-color: #f0ad4e !important;
}
.bd-callout {
  padding: 5px;
  border: 1px solid #e9ecef;
  border-left-width: 0.25rem;
  border-radius: 0.25rem;
}
/* End of Thulasy changes */
.ant-upload-list-item-name {
  color: initial !important;
}
/* Hide error tooltip in Ant Design Upload component */
.ant-tooltip.ant-tooltip-placement-topLeft {
  display: none !important;
}
.custom-table .ant-table-thead > tr > th {
  font-family: "Poppins" !important;
  font-size: 13px;
}
.custom-table .ant-table-tbody > tr > td {
  font-family: "Poppins" !important;
  font-size: 12px;
  padding-top: 9px; /* Adjust top padding */
  padding-bottom: 9px; /* Adjust bottom padding */
  line-height: 2; /* Adjust line height */
}
.tableheading {
  font-size: 18px !important;
  font-weight: bold !important;
}
.loginalign {
  margin-left: 30px !important;
  margin-right: 30px !important;
}
p {
  text-align: justify !important;
}
.dropdown-item {
  font-size: 12px !important;
}
.coporatesignup .css-dev-only-do-not-override-190m0jy.ant-form-item {
  margin-bottom: 17px !important;
}
.subheading {
  font-size: 18px !important;
}
.websiteimage {
  height: 300px !important;
  width: 400px !important;
}
.service-block {
  margin: 20px;
  border-radius: 5px;
  width: 200px;
  min-height: 130px;
  display: inline-table;
}
.service-block.color-bg {
  background: #783153 !important;
  color: #fff !important;
}
.service-block .service-icon {
  margin-bottom: 30px;
}
.service-block.color-bg i,
.services .service-block.color-bg h3,
.services .service-block.color-bg p {
  color: #fff;
}
.service-block h3 {
  color: #fff;
  font-size: 18px;
  font-weight: bold;
}
.service-block p {
  font-size: 14px;
  color: #fff;
}
.service-block h3 {
  color: #333;
  font-size: 18px;
  font-weight: bold;
}
.service-block p {
  font-size: 14px;
  color: #666;
}
.service-block.color-bg i,
.services .service-block.color-bg h3,
.services .service-block.color-bg p {
  color: #fff;
}
.service-block i {
  font-size: 40px;
  color: #783153;
}
.custom-card {
  border: none;
  overflow: hidden;
  border-radius: 20px;
  height: 250px;
  box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.2);
  position: relative;
}
.custom-card img {
  width: 100%;
  height: auto;
  border-radius: 20px 20px 0 0;
}
.custom-card .card-content {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 20px;
  border-radius: 0 0 20px 20px;
  color: #ffffff;
  background: linear-gradient(360deg, #000000 15.94%, rgba(0, 0, 0, 0) 90.42%);
}
.custom-card .card-content h2 {
  font-size: 24px;
  margin-bottom: 10px;
  font-weight: 800;
}
.custom-card .card-content p {
  font-size: 16px;
  margin-bottom: 20px;
}
.custom-card .card-content .learn-more {
  color: #efba33;
  font-weight: bold;
  text-decoration: none;
}
.custom-card:hover {
  cursor: pointer;
  transform: scale(0.98);
  box-shadow: 0 0 5px -2px rgba(0, 0, 0, 0.3);
}
.custom-card:hover .card-content {
  transition: all 800ms cubic-bezier(0.19, 1, 0.22, 1);
  margin-top: 30px;
}
.causecardtxt {
  font-size: 10px !important;
}
.circular-progress {
  position: relative;
  width: 120px;
  height: 120px;
}
.circular-progress .progress-bar {
  position: absolute;
  width: 100%;
  height: 100%;
  clip: rect(0, 120px, 120px, 60px); /* Half of the circle */
  background-color: var(--bg-color, #ddd);
  border-radius: 50%;
}
.circular-progress .progress-bar::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  clip: rect(0, 60px, 120px, 0); /* Other half of the circle */
  background-color: var(--progress-color, #007bff);
  border-radius: 50%;
}
.circular-progress .inner-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 20px;
  color: #000;
}
.ant-progress-circle-trail {
  stroke-width: var(--trail-width);
}
.progress-container {
  position: relative;
  display: inline-block; /* Adjust as needed */
}
.squareindicator2 {
  height: 10px;
  width: 10px;
  background-color: #e9bf53;
}
.corpdashbordtxt {
  font-size: 13px;
  font-weight: 700;
}
.custom-progress .ant-progress-text {
  font-size: 28px; /* Adjust the font size as needed */
}
.card-full-height {
  display: flex !important;
  flex-direction: column !important;
  height: 100% !important;
}
.smallsubheading {
  font-size: 13px !important;
  font-weight: 700;
}
.squareindicator1 {
  float: left;
  height: 10px;
  width: 10px;
  background-color: #87677f;
  margin-right: 8px;
  margin-top: 10px;
}
.clearfix::after {
  content: "";
  display: table;
  clear: both;
}
.corpcard {
  max-height: 100px !important;
  padding: 0px;
}
.blur {
  filter: blur(2px);
}
.loader {
  position: fixed;
  top: 50%; /* Center vertically */
  left: 50%; /* Center horizontally */
  transform: translate(-50%, -50%);
  border-radius: 5px;
  display: flex;
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
  z-index: 9999; /* Ensure loader is above other elements */
}
.loader img {
  max-width: 50%;
  max-height: 50%;
}
.nav-link.active {
  color: #ffd700; /* Gold color or any other style for the active link */
  font-weight: bold;
}

.custom-button {
  margin-right: 10px;
}
.custom-button:last-child {
  margin-right: 0;
}
.goal-raised-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.goal,
.raised {
  display: inline-block;
}

.modalfont {
  font-family: "Cookie", cursive;
}

@media (min-width: 768px) {
  .custom-modal .ant-modal-content {
    width: 900px; /* Adjust width as needed */
  }
}

.form-floating > option{
  color: #2d1621;
  font-family: "Poppins";
  font-weight: normal;
  /* font-weight: 700; */
  font-size: 12px;
}
.form-select > select{
  color: #2d1621;
  font-family: "Poppins";
  font-weight: normal;
  /* font-weight: 700; */
  font-size: 12px;

}
/* Fixed width for all tags */
.status-tag {  
  border-radius: 4px;
  margin: 2px;
  width: 120px; /* Set fixed width */
  text-align: center; /* Center the text inside the tag */
  display: inline-block; /* Ensure it behaves like an inline element */
}

/* stepform line color */
:where(.css-dev-only-do-not-override-190m0jy).ant-steps .ant-steps-item-finish>.ant-steps-item-container>.ant-steps-item-content>.ant-steps-item-title::after {
  background-color: #d69228;
}
.agreement{
  width: 120px;
}